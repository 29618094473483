<template>
  <div class="font-s-14 app-wrapper">
    <head-child class="head flex">
      <template #left>
        <div class="flex jcc t-a-c" v-if="boo2" @click="Switch">
          <!-- <van-loading type="spinner" v-if="boo" /> -->
          <div class="ellipsis mr-5" >
            {{ address }}
          </div>
          <div >
            <i class="iconfont icon-iconfontarrows1"></i>
          </div>
        </div>
        <div class="iconfont icon-zuojiantou" v-else @click="goback"></div>
      </template>

      <template #middle>
        <van-search v-model="value" placeholder="搜索商品" @focus="seach1" style="background-color: rgb(255, 255, 255,0);" />
      </template>
      <template #right>
        <div @click="seach" v-if="boo3">搜索</div>
        <div @click="delSeach" v-else>取消</div>
      </template>
    </head-child>
    <!-- 正常页面 -->
    <div v-if="boo2">
      <van-tabs v-model="active" sticky title-active-color="#d33b2e" @click="scrollToAll">
        <van-tab title="蛋糕">
          <div style="font-size: 1rem;color: #888; width: 100%;text-align: center;" v-if="titles.length == 0">
            <van-empty class="empt font-s-16">
            </van-empty>{{ tig }}
          </div>
          <div v-else>
            <van-tabs @click="scrollTo" v-model="navIndex">
              <van-tab v-for="(item, index) in titles" :title="item.name" :key="index" />
            </van-tabs>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
              <div>
                <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
                  <div class="grid-container">
                    <div v-for="(item, index) in list" :key="index" class="grid-item">
                      <div style=" padding: 0.2rem;" @click="goDetail(item.id, 3)">
                        <div class="card-item">
                          <van-image lazy-load fit="cover" radius="0.5rem"
                            style="border-bottom-left-radius:0;border-bottom-right-radius:0;width: 100%;height: 12rem;"
                            :src="item.image_path"  >
                          </van-image>
                          <span class="textTitle" style="margin-left: 0.5rem;margin-right: 0.5rem;">{{
                            item.title }}</span>
                          <div style="color: #d33b2e;margin-left: 0.5rem; font-size: 1rem;height:1.875rem">{{ item.price
                          }}点 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </div>
            </van-pull-refresh>
          </div>
        </van-tab>
        <van-tab title="零食">
          <div style="font-size: 1rem;color: #888;width: 100%;text-align: center;" v-if="titles.length == 0">
            <van-empty class="empt font-s-16">
            </van-empty>
            {{ tig }}
          </div>
          <div v-else>
            <van-tabs @click="scrollTo" v-model="navIndex">
              <van-tab v-for="(item, index) in titles" :title="item.name" :key="index" />
            </van-tabs>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
              <div>
                <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
                  <div class="grid-container">
                    <div v-for="(item, index) in list" :key="index" class="grid-item">
                      <div style=" padding: 0.2rem;" @click="goDetail(item.id, 4)">
                        <div class="card-item">
                          <van-image lazy-load fit="cover" radius="0.5rem"
                            style="border-bottom-left-radius:0;border-bottom-right-radius:0;width: 100%;height: 12rem;"
                            :src="item.image_path" >
                          </van-image>
                          <span class="textTitle" style="margin-left: 0.5rem;margin-right: 0.5rem;">{{
                            item.title }}</span>
                          <div style="color: #d33b2e;margin-left: 0.5rem; font-size: 1rem;height:1.875rem"> {{ item.price
                          }}点 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </div>
            </van-pull-refresh>
          </div>
        </van-tab>
        <van-tab title="鲜花">
          <div style="font-size: 1rem;color: #888;width: 100%;text-align: center;" v-if="titles.length == 0">
            <van-empty class="empt font-s-16">
            </van-empty>
            {{ tig }}
          </div>
          <div v-else>
            <van-tabs @click="scrollTo" v-model="navIndex">
              <van-tab v-for="(item, index) in titles" :title="item.name" :key="index" />
            </van-tabs>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
              <div>
                <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
                  <div class="grid-container">
                    <div v-for="(item, index) in list" :key="index" class="grid-item">
                      <div style=" padding: 0.2rem;" @click="goDetail(item.id, 5)">
                        <div class="card-item">
                          <van-image lazy-load fit="cover" radius="0.5rem"
                            style="border-bottom-left-radius:0;border-bottom-right-radius:0;width: 100%;height: 12rem;"
                            :src="item.image_path" >
                          </van-image>
                          <span class="textTitle" style="margin-left: 0.5rem;margin-right: 0.5rem;">{{
                            item.title }}</span>
                          <!-- <div style="display: flex; flex-direction: row;margin: 0.5rem;">
                            <van-tag color="#ffe1e1" text-color="#d33b2e">包邮</van-tag>
                            <van-tag color="#ffe1e1" text-color="#d33b2e" style="margin-left: 0.2rem;">京东快递</van-tag>
                          </div> -->
                          <div style="color: #d33b2e;margin-left: 0.5rem; font-size: 1rem;height:1.875rem"> {{ item.price
                          }}点 </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </div>
            </van-pull-refresh>
          </div>
        </van-tab>
        <!-- <van-tab title="自营">
          <div style="font-size: 1rem;color: #888;width: 100%;text-align: center;" v-if="zylist.length == 0">
            <van-empty class="empt font-s-16">
            </van-empty>
            {{ tig }}
          </div>
          <div v-else>
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
              <div>
                <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
                  <div class="grid-container">
                    <div v-for="(item, index) in zylist" :key="index" class="grid-item">
                      <div style=" padding: 0.2rem;" @click="goZYDetail(item.id)">
                        <div class="card-item">
                          <van-image lazy-load fit="cover" radius="0.5rem"
                            style="border-bottom-left-radius:0;border-bottom-right-radius:0;width: 100%;height: 12rem;"
                            :src="item.coverImage" :error-icon="require('@/assets/images/logo_loading.png')">
                          </van-image>
                          <span class="textTitle" style="margin-left: 0.5rem;margin-right: 0.5rem;">{{
                            item.name }}</span>

                          <div style="color: #d33b2e;margin-left: 0.5rem; font-size: 1rem;height:1.875rem">￥ {{
                            item.costPrice }} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </div>
            </van-pull-refresh>
          </div>
        </van-tab> -->
      </van-tabs>
    </div>
    <!-- 搜索页面 -->
    <seachview v-else ref="seach" :value="value" @value="seachvalue" :type="active + 1"></seachview>
    <!-- <button v-if="showTop" class="top" @click="toTop">^</button> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import HeadChild from "../../components/HeadChild";
import NaigateTool from '../../components/index/NavigateTool';
import Seachview from "../../components/index/Seachview.vue";


export default {
  name: "CakeList",
  props: {},
  data() {
    return {
      // 容器宽度百分比
      width: 100,
      // 滑轮值
      // range: 100,
      //是否根据容器尺寸自动计算重绘
      autoResize: true,
      //列数
      col: 2,
      //是否始终填满容器
      fillBox: false,
      // //列宽-有指定列数则此属性失效
      // colWidth: (pc ? window.innerHeight : window.innerWidth) / 2,
      // // 列数和列宽模式切换
      // mode: 0,
      list: [],
      active: 0,
      loading2: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      checkList: [], // 列表数据
      tabindexL: 1,
      loading: false,
      titles: [],
      cakeForm: {
        brandId: '',
        cardId: '',
        cityCode: '',
        current: 1,
        size: 10,
        productType: 1,

      },
      zyForm: {
        cardId: "",
        current: 1,
        productName: "",
        size: 10,
        cityCode: '',
      },
      zylist: [],
      city: null,
      value: null,
      // 定位
      boo: true,
      address: null,
      // 非搜索状态
      boo2: true,
      // 搜索 取消
      boo3: true,
      // 刷新
      isLoading: false,
      cakeIndex: 0,
      navIndex: 0,
      showTop: false,
      cityCode: null,
      tig: '',
      productType: 1,
    };
  },
  components: {
    HeadChild,
    Seachview,
    NaigateTool,
  },
  methods: {
    toTop() {
      this.$nextTick(() => {
        document.body.scrollTop = 0
      })
    },
    nextPage() { // 加载下一页
      if (this.list.length >= 10) {
        this.cakeForm.current += 1
        this.getGoodList();
      }
    },
    // 刷新
    onRefresh() {
      setTimeout(() => {
        this.cakeForm.current = 1
        this.getGoodList();
        this.isLoading = false;
      }, 1000);
    },

    getData() {
      this.$api
        .getBrandInfo(this.cityCode, this.cakeForm.cardId, this.productType)
        .then((res) => {
          this.titles = res.data
          if (res.data.length == 0) {
            this.tig = '该城市暂未有商品'
          } else {
            this.cakeForm.brandId = res.data[0].id
            this.cakeForm.current = 1
            this.getGoodList()
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGoodList() {
      this.cakeForm.cityCode = this.cityCode
      this.$api
        .getProductHotList(this.cakeForm)
        .then((res) => {
          if (this.cakeForm.current == 1) {
            this.list = res.data.records
          } else {
            this.list.push(...res.data.records)
          }
          if (this.cakeForm.current >= res.data.pages) {
            this.finished = true
          }
          this.loading = false;
          this.loading2 = false;
        })
        .catch((err) => {
          console.log(err);
        });

    },
    // 定位
    getAddress() {
      let _this = this;
      this.boo = true;
      this.address = null;
      AMap.plugin("AMap.Geolocation", function () {
        let geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: false,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: "RB",
        });
        geolocation.getCityInfo(getCity)
        function getCity(status, result) {
          if (status != 'complete') {
            _this.address = data.addressComponent.city;
            _this.boo = false;
            localStorage.setItem("address", _this.address);
            _this.$store.commit("setAddress", _this.address)
            localStorage.setItem("cityCode", '');
            _this.$store.commit("cityCode", '')
          } else {
            _this.address = result.city.replace("市", "");
            _this.boo = false;
            localStorage.setItem("address", _this.address);
            _this.$store.commit("setAddress", _this.address)
            localStorage.setItem("cityCode", result.adcode);
            _this.$store.commit("cityCode", _this.adcode)
          }
        }
      });
    },

    // 位置选择
    Switch() {
      this.$router.push("SelectAdderss");
    },
    // 调用子组件的方法
    seach() {
      this.$refs.seach.seach(this.value);
      this.boo3 = false;
    },
    // 取消
    delSeach() {
      this.value = null;
      this.boo3 = true;
      this.$refs.seach.history = true;
      this.$refs.seach.getHistroy();
    },
    // 输入框获取焦点
    seach1() {
      this.boo2 = false;
    },
    // 返回
    goback() {
      this.boo2 = true;
    },
    goDetail(val, type) {
      this.$router.push({
        path: "/Details",
        query: {
          id: val,
          specId: val.specId,
          brandId: this.cakeForm.brandId,
          productType: type
        },
      });
    },
    goZYDetail(val) {
      this.$router.push({
        path: "/Details",
        query: {
          id: val,
        },
      });
    },
    //子组件分发的事件  点击历史记录重新给输入框赋值
    seachvalue(val) {
      this.value = val;
    },
    scrollToAll(index) {
      if (index == 3) {
        this.zyForm.current == 1
        this.loading = true
        this.titles.length = 0
        this.list.length = 0
        this.queryAutarkyCakeInfo()
      } else {
        this.cakeForm.productType = index + 1
        this.productType = index + 1
        this.titles.length = 0
        this.list.length = 0
        this.loading = true
        this.getData()
      }
      this.navIndex = 0

    },
    /**
       * 蛋糕跳转到指定索引的元素
       * @param index
       */
    scrollTo(index) {
      this.cakeIndex = index
      this.cakeForm.brandId = this.titles[index].id
      this.cakeForm.current = 1
      this.list.length = 0
      this.loading = true
      this.getGoodList()
    },
    scrollToTop() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let browserHeight = window.outerHeight;
      if (scrollTop > browserHeight) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    },
    //自营蛋糕地址
    queryAutarkyCakeInfo() {
      this.zyForm.cityCode = this.cityCode
      this.$api
        .queryAutarkyCakeInfo(this.zyForm)
        .then((res) => {
          if (this.zyForm.current == 1) {
            this.zylist = res.data.records
          } else {
            this.zylist.push(...res.data.records)
          }
          if (this.zyForm.current >= res.data.pages) {
            this.finished = true
          }
          this.loading = false;
          this.loading2 = false;
        })
        .catch((err) => {
        });
    },
    getBaiduAddress() {
      //在此调用api
      var geolocation = new BMapGL.Geolocation();
      geolocation.getCurrentPosition(a => {
        //可以获取不同级别的位置信息，这里使用城市作为实例
        this.getCityCode(a.address.city)
      })
    },
    getCityCode(city) {
      let _this = this
      this.cityList.forEach(element => {
        if (element.city == city) {
          _this.boo = false;
          _this.address = result.city.replace("市", "");
          localStorage.setItem("address", _this.address);
          localStorage.setItem("cityCode", element.cityid);
          _this.$store.commit("cityCode", element.cityid)
          _this.$store.commit("setAddress", _this.address)
          this.cityCode = element.cityid
        }
      });
    },
    getCityList() {
      this.$get('/sys_city_cities.json').then((res) => {
        this.cityList = res.data
        this.getBaiduAddress()
      }, err => {
        console.log(err)
      })
    },

  },
  mounted() {
    this.cakeForm.cardId = localStorage.getItem("cardId");
    this.zyForm.cardId = this.cakeForm.cardId
    // // 是否有手动选择地址
    let adrs = localStorage.getItem("address");
    this.cityCode = localStorage.getItem("cityCode");
    this.active = this.$route.query.index
    if (adrs !== null) {
      this.address = adrs;
      this.boo = false;
    } else {
      this.getCityList();
    }
    // this.getData();
    this.active = Number(this.$route.query.index)
    this.scrollToAll(this.active)
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollToTop, false)
  },
  activated() {
    const scrollTop = this.$route.meta.scrollTop;
    const $wrapper = document.querySelector('.app-wrapper');
    if (scrollTop && $wrapper) {
      $wrapper.scrollTop = scrollTop;
    }
  },
  beforeRouteLeave(to, from, next) {
    // console.log('path',to.path )
    if (to.path === '/Details' || to.path === '/ZYDetails') {
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    next()
  },
  computed: {},
  watch: {
    // 输入框的值
    value(val, oldval) {
      if (oldval !== "") {
        this.boo3 = true;
      }
    },
    // 地址
    address() {
      return this.$store.state.address;
    },
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.scrollToTop)
  },
};
</script>

<style lang='scss' scoped>
.head {
  padding: 0.5rem 0.2rem;
  height: 2rem;
}

.van-search__content {
  background-color: rgb(255, 255, 255, 0);
}

.top {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 50px;
  right: 20px;
  background-color: aqua;
}

.empty {
  transform: translateY(50%);
}

/*瀑布流对应布局开始*/
.main {
  height: max-height;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;

  /*瀑布流对应布局开始*/
  .waterfall-item {
    margin: 5px;
    background: white;
    box-sizing: border-box;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    padding-bottom: 1rem;
  }

  .waterfall-item img {
    width: 100%;
    display: block;
  }

  .bounceIn {
    animation-name: bounceIn;
    animation-duration: 0.6s;
    animation-timing-function: linear;
    transform-origin: center center;
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.3);
      -ms-transform: scale(0.3);
      transform: scale(0.3);
    }

    50% {
      opacity: 1;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5%;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  justify-content: space-between;
}

.grid-item {
  flex-basis: 49%;
  padding-bottom: 0.5rem;
}

.card-item {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
}

.textTitle {
  font-family: Helvetica Neue,Helvetica,sans-serif;
  font-size: 0.875rem;
  
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.5rem;

  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

/*瀑布流对应布局结束*/

//
::v-deep .van-tabs__line {
  background-color: #d33b2e;
  border-radius: 3px;
}

::v-deep .van-tab {
  font-family:Helvetica Neue,Helvetica,sans-serif;
  color: #696969;
  letter-spacing: 0;
  font-weight: 500;
}
</style>